import React ,{useState, useEffect, useLayoutEffect} from 'react'

const getScrollbarWidth = () => {

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;

}

const useBodyScrollable = () => {
  const [bodyScrollable, setBodyScrollable] = useState(
      document.body.scrollHeight > window.innerHeight
  )
  useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
          setBodyScrollable(document.body.scrollHeight > window.innerHeight)
      })
      resizeObserver.observe(document.body)
      return () => {
          resizeObserver.unobserve(document.body)
      }
  }, [])
  return bodyScrollable
}

// const scrollbarWidth = getScrollbarWidth()
// function MyLayoutComponent() {
//   const bodyScrollable = useBodyScrollable()
//   useLayoutEffect(() => {
//       if (bodyScrollable) {
//           document.body.style.paddingRight = '0px'
//       } else {
//           document.body.style.paddingRight = `${scrollbarWidth}px`
//       }
//   }, [bodyScrollable])
//   return // ...
// }
// MyLayoutComponent();

const Loader = ({percentage}) => {
  const scrollbarWidth = getScrollbarWidth()
  const bodyScrollable = useBodyScrollable()
  useLayoutEffect(() => {
      if (bodyScrollable) {
          document.body.style.paddingRight = '0px'
      } else {
          document.body.style.paddingRight = `${scrollbarWidth}px`
      }
  }, [bodyScrollable, scrollbarWidth])

  return (
    
    <div className="loader-wrapper">
        <div id="logo_preloader" className="logo_preloader_logo"><div className="logo_preloader_loader"><div style={{ bottom: percentage + '%' }}></div></div><div className="logo_preloader_percentage" >{percentage}%</div></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>

    // <div id="logo_preloader" className="logo_preloader_logo"><div className="logo_preloader_loader"><div style={{ bottom: percentage + '%' }}></div></div><div className="logo_preloader_percentage" >{percentage}%</div></div>
  )
}

export default Loader
