import { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import HeaderSticky from "./HeaderSticky";
import ResponsiveMenu from "./ResponsiveMenu";

const HeaderTwo = ({ styles, disableSticky, searchDisable, buttonStyle }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const sticky = HeaderSticky(200);
  const classes = `header-default ${sticky ? "sticky" : ""}`;
  const stickyStatus = disableSticky ? "" : " header-sticky";
  return (
    <>
      <header
        className={`edu-header ${stickyStatus} ${styles || ""} ${
          classes || ""
        }`}
      >
        <div className="row align-items-center">
          <div className="col-lg-4 col-xl-2 col-md-6 col-6">
            <div className="logo">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <img
                  className="logo-light"
                  src="/images/logo/logo.png"
                  alt="Main Logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-8 d-none d-xl-block">
            <nav className="mainmenu-nav d-none d-lg-block">
              <Nav />
            </nav>
          </div>

          <div className="col-lg-8 col-xl-2 col-md-6 col-6 neg-m-l">
            <div className="header-right d-flex justify-content-end">
              <div className="header-menu-bar">
                <div className="quote-icon quote-user d-md-block ml_sm--5">
                  <Link
                    className={`edu-btn btn-medium left-icon plr--10 header-button btn-primary ${
                      buttonStyle || ""
                    }`}
                    to={process.env.PUBLIC_URL + "/pricing"}
                  >
                    <i className="ri-secure-payment-line"></i>
                    <span>Make a Payment</span>
                  </Link>
                </div>

                <div className="mobile-menu-bar ml--15 ml_sm--5 d-block">
                  <div className="hamberger">
                    <button
                      className="white-box-icon- hamberger-button header-menu rts-btn btn-secondary menu-btn"
                      onClick={onCanvasHandler}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                      >
                        <rect
                          y="12"
                          width="18"
                          height="2"
                          fill="#3B38EB"
                        ></rect>
                        <rect
                          x="5"
                          y="6"
                          width="8"
                          height="2"
                          fill="#3B38EB"
                        ></rect>
                        <rect width="18" height="2" fill="#3B38EB"></rect>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      />
    </>
  );
};

export default HeaderTwo;
