import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import PostFour from '../post/PostFour';

const HomeFiveBlog = ({blogs}) => {
    return (
        <div className="eduvibe-home-five-blog edu-blog-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Blog & News"
                            title = "Our Latest News Feed"
                        />
                    </div>
                </div>
                <div className="row g-5 mt--10">
                    <PostFour blogs={blogs} />
                </div>

                
            </div>

            
        </div>
    )
}

export default HomeFiveBlog;