import React from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import ScrollAnimation from "react-animate-on-scroll";

const AboutFive = ( { wrapperClass, features } ) => {
    return (
        <div className={`home-three-about edu-about-area about-style-4 bg-color-white ${ wrapperClass || 'edu-section-gapBottom' }`}>
            <div className="container eduvibe-animated-shape">
                <div className="row g-lg-5 g-md-5 g-sm-5">
                    <div className="col-lg-12 col-xl-6">
                        <div className="gallery-wrapper">
                            <img src="/images/about/about-05/about-group-01.jpg" alt="About Images" />
                            <div className="image-2">
                                <Parallax translateY={[-25, 15]} tagouter="figure">
                                    <img src="/images/about/about-05/about-group-02.png" alt="About Images" />
                                </Parallax>
                            </div>
                            <div className="image-3">
                                <Parallax translateY={[40, -40]} tagouter="figure">
                                    <img src="/images/about/about-05/about-group-03.png" alt="About Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            <div className="section-title text-start">
                                <span className="pre-title">Why Choose US</span>
                                <h3 className="title">Our Core Features</h3>
                            </div>
                            <div className="feature-style-6 mt--40">

                            {features && features.length > 0 && (
                <div className="feature-list-wrapper-">
                  {features.filter(item => item.core_feature > 0).map((data, i) => (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      className="edu-feature-list"
                      animateOnce={true}
                      key={i}
                    >
                                    <div className="icon">
                                        <img src="/images/about/about-05/star-feature.svg" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">{data.title}</h6>
                                        <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p>
                                    </div>
                                
                    </ScrollAnimation>
                  ))}
                </div>
              )}
                                

                            </div>
                        </div>
                        <div className="read-more-btn mt--30">
                            <Link className="edu-btn" to="/about-us">Know About Us <i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default AboutFive;