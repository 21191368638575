import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import TestimonialFour from '../../components/testimonial/TestimonialFour';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import TestimonialSectionTwo from '../../components/testimonial-section/TestimonialSectionTwo';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';

const Testimonial = ({testimonials}) => {
    return (
        <>
            <SEO title="Testimonial" />
            <HeaderTwo styles="header-transparent- header-style-2" />
                <BreadcrumbOne 
                    title="Testimonial"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Testimonial"
                />
                

                <TestimonialSectionFour testimonials={testimonials} />



                <FooterOne />

        </>
    )
}

export default Testimonial;