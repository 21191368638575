
import React from 'react';
import { Link } from 'react-router-dom';

const AboutSeven = () => {
    return (
        <div className=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="eduvibe-about-1-img-wrapper">
                                <img className="image-1" src="/images/about/about-07/about-one.jpg" alt="About Images" />
                               
                            </div>
                            <div className="circle-image-wrapper">
                                <img className="image-2" src="/images/about/about-07/about-two.jpg" alt="About Images" />
                                <div className="circle-image">
                                    <span></span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <div className="section-title text-start">
                                <span className="pre-title">About Us</span>
                                <h3 className="title">Valoir Inc is a total solution to streamline and automate international student recruitment process.</h3>
                            </div>
                            <p className="description mt--40 mt_md--20 mt_sm--20">We are the pioneers in the field of international student recruitments, recruitment process automation, international digital marketing and digital academic networking. Valoir Inc is world's largest platform where institutions can find relevant agents from around the globe and achieve diversified international student targets at minimal costs.</p>


                                <h6 className="subtitle mb--20">The main areas where we can help</h6>

                            <div className="about-feature-list">
                                <div className="row g-5">
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">90%</h6>
                                                <p className="feature-description">Automating student recruitment process</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">9/10</h6>
                                                <p className="feature-description">Achieving intake recruitment targets</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default AboutSeven;