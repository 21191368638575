import React from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const ResponsiveMenu = ( { show, onClose, showSearch, onSearch } ) => {
    var elements = document.querySelectorAll( '.popup-mobile-menu .has-droupdown > a' );
    var elementsTwo = document.querySelectorAll( '.popup-mobile-menu .with-megamenu > a' );
    for( var i in elements ) {
        if( elements.hasOwnProperty( i ) ) {
            elements[i].onclick = function() {
                this.parentElement.querySelector( '.submenu').classList.toggle( 'active' );
                this.classList.toggle( 'open' );
            }
        }
    }

    for( var j in elementsTwo ) {
        if(elementsTwo.hasOwnProperty(i)) {
            elementsTwo[j].onclick = function() {
                this.parentElement.querySelector( '.rn-megamenu' ).classList.toggle( 'active' );
                this.classList.toggle( 'open' );
            }
        }
    }

    return (
        <>
            <div className={ `popup-mobile-menu ${ show ? 'active': ''}` }>
                <div className="inner">
                    <div className="header-top">
                        <div className="logo">
                            <Link to={process.env.PUBLIC_URL + '/' }>
                                <img className="side-logo" src="/images/logo/logo.png" alt="Main Logo" />
                            </Link>
                        </div>
                        <div className="close-menu">
                            <button className="close-button" onClick={ onClose }>
                                <i className="ri-close-line"></i>
                            </button>
                        </div>
                    </div>

                    <div className="body d-none d-xl-block">

                <p className="disc">
                We are the pioneers in the field of international student recruitments, recruitment process automation, international digital marketing and digital academic networking. 
                <img className="icef-logo" src="http://valoirinc.com/wp-content/uploads/2022/11/icef-agency-status-300x300.jpg" alt="Main Logo" />
                <span id='iasBadge' data-account-id='5301'></span><script async defer crossOrigin="anonymous" src="https://www-cdn.icef.com/scripts/iasbadgeid.js"></script>
                </p>
                <div className="social-wrapper-two">
                    <ul className="social-area">
                        <li data-sal-delay="150" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-facebook-fill"></i></a></li>
                        <li data-sal-delay="250" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-twitter-fill"></i></a></li>
                        <li data-sal-delay="350" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-linkedin-fill"></i></a></li>
                        <li data-sal-delay="450" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-skype-fill"></i></a></li>
                    </ul>
                </div>
                <div className="get-in-touch mt--30">
                   
                    <div className="h6 title">Get In Touch</div>
                   
                    <div className="wrapper">
                       <div className="single">
                            <i className="icon-phone-fill"></i>
                            <a href="tel: +1-647-618-5678">+1-647-618-5678</a>
                        </div>

                        <div className="single">
                            <i className="icon-phone-fill"></i>
                            <a href="tel: +1-514-220-9752">+1-514-220-9752</a>
                        </div>
                        
                        <div className="single">
                            <i className="icon-mail-line-2"></i>
                            <a href="mailto:info@valoirinc.com">info@valoirinc.com</a>
                        </div>
                        
                        <div className="single">
                            <i className="ri-global-fill"></i>
                            <a href="http://valoirinc.com/">www.valoirinc.com</a>
                        </div>
                        
                        <div className="single">
                            <i className="icon-map-pin-line"></i>
                            <a href="#">CANADA, USA, INDIA</a>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="body-mobile d-block d-xl-none">
                    <Nav />

                    <div className="social-wrapper-two">
                    <ul className="social-area">
                        <li data-sal-delay="150" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-facebook-fill"></i></a></li>
                        <li data-sal-delay="250" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-twitter-fill"></i></a></li>
                        <li data-sal-delay="350" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-linkedin-fill"></i></a></li>
                        <li data-sal-delay="450" data-sal="slide-up" data-sal-duration="800" className="sal-animate"><a href="#"><i className="ri-skype-fill"></i></a></li>
                    </ul>
                </div>
            </div>        
                </div>
            </div>

            <div className={ `edu-search-popup ${ showSearch ? 'open': ''}` }>
                <div className="close-button">
                    <button className="close-trigger" onClick={ onSearch }><i className="ri-close-line"></i></button>
                </div>
                <div className="inner">
                    <form className="search-form" action="#">
                        <input type="text" className="eduvibe-search-popup-field" placeholder="Search Here..." />
                        <button className="submit-button"><i className="icon-search-line"></i></button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ResponsiveMenu;