import React from 'react';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactMeForm from '../../components/contact/ContactMeForm';
import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';

const ContactMe = () => {
    return (
        <>
            <SEO title="Contact Us" />
            <HeaderTwo styles="header-transparent- header-style-2" />
                <BreadcrumbOne 
                    title="Contact Us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact Us"
                />
                <div className="eduvibe-contact-me-top edu-contact-me-area about-me-1 edu-section-gap bg-color-white">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/images/contact/contact-me/contact-001.jpg" alt="Contact Me" />
                                        <div className="content-overlay">
                                            <h4 className="title">Don’t Be Hesitate To <br /> Contact With US</h4>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-title text-start mb--45">
                                    <span className="pre-title">Contact Us</span>
                                    <h3 className="title">Send Us A Message</h3>
                                </div>
                                <ContactMeForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                            </div>
                        </div>

                        
                    </div>
                </div>

                <div className="eduvibe-contact-me-bottom edu-contact-address contact-address-bottom-shape edu-section-gapBottom">
                    <div className="container eduvibe-animated-shape">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">LOCATIONS</span>
                                    <h3 className="title">Our Offices Locations</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row g-5 mt--20">
                            <div className="col-lg-4 col-md-6">
                                <div className="contact-address-card-2">
                                    <div className="inner">
                                        <div className="icon">
                                        <i className="icon-Headphone"></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Contact</h6>
                                            <p><span className="subtitle">Mobile: </span><a href="tel: +1-647-618-5678"> +1-647-618-5678</a></p>
                                            <p><span className="subtitle">Mobile: </span><a href="tel: +1-514-220-9752"> +1-514-220-9752</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="contact-address-card-2">
                                    <div className="inner addresses">
                                        <div className="icon">
                                        <i className="icon-map-pin-line"></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Addresses</h6>
                                            <p><span className='subtitle'>Head Office:</span> 1250 RUE DUFORT, MONTREAL, QC H3H 2B6, CANADA</p>
                                            <p><span className='subtitle'>Branch Office:</span> 2322 SHATTUCK AVE, BERKELEY, CALIFORNIA 94704, USA</p>
                                            <p><span className='subtitle'>Branch Office:</span> 2D, Lakshmi Rukmini Estates, Sikh Village, Secunderabad,Telangana, India</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="contact-address-card-2">
                                    <div className="inner">
                                        <div className="icon">
                                        <i className="icon-clock"></i>
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Working Hours</h6>
                                            <p><span className="subtitle">Monday - Friday: </span><span className="text">09:00 - 20:00</span></p>
                                            <p><span className="subtitle">Sunday & Saturday: </span><span className="text">10:30 - 22:00</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                    
                </div>
                <FooterOne />  
        </>
    )
}
export default ContactMe;