import React , { useState } from 'react'
import FooterOne from '../../common/footer/FooterOne'
import HeaderTwo from '../../common/header/HeaderTwo'
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const ClientsPage = ({brands}) => {
    const [visible, setVisible] = useState('');
  return (
    <>
            <SEO title="Our Clients" />
            <HeaderTwo styles="header-transparent- header-style-2" />
                <BreadcrumbOne 
                    title="Our Clients"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Our Clients"
                />
                <div className="edu-intructor-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">our partners</span>
                                    <h3 className="title">We work with leading colleges</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 mt--5">
                            { 
                                brands.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--45" key={item.id}>
                                    <div className="edu-instructor-grid edu-instructor-2">
            <div className="edu-instructor">
                <div className="inner">
                    <div className="thumbnail">
                        <Link to="">
                            <img src={item.image} alt={item.name} />
                        </Link>
                    </div>
                    <div className="team-share-info">
                        <a className="linkedin" href={item.linkedInUrl}><i className="icon-linkedin"></i></a>
                        <a className="facebook" href={item.facebookUrl}><i className="icon-Fb"></i></a>
                        <a className="twitter" href={item.twitterUrl}><i className="icon-Twitter"></i></a>
                        <a className="youtube" href={item.youtubeUrl}><i className="icon-youtube"></i></a>
                    </div>
                </div>
            </div>
            <div className="edu-instructor-info">
                <h5 className="title">
                    <Link to="">{item.name}</Link>
                </h5>
            </div>
        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            <FooterOne />
        </>
  )
}

export default ClientsPage
