import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import TestimonialOne from '../testimonial/TestimonialOne';

const TestimonialSectionFour = ({testimonials}) => {
    return (
        <div className="eduvibe-testimonial-one- edu-testimonial-area edu-section-gap bg-image-">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-title text-center">
                                    <span className="pre-title">Testimonial</span>
                                    <h3 className="title">Our Satisfied Customers</h3>
                    </div>
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    <div className="col-lg-6 col-12">
                        <div className="satisfied-learner">
                            <div className="thumbnail">
                                <img src="/images/testimonial-section/testimonial-001.jpg" alt="Education Images" />
                            </div>
                            <div className="trophy-content bounce-slide">
                                <div className="icon">
                                    <img src="/images/testimonial-section/trophy-1.svg" width="50" alt="Trophy Images" />
                                </div>
                                <div className="content">
                                    <h6 className="text">4K+</h6>
                                    <span><strong>HAPPY CLIENTS</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <TestimonialOne testimonials={testimonials} item="3" />
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default TestimonialSectionFour;