import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import AboutUsOne from "./pages/innerpages/AboutUsOne";
import BlogStandard from "./pages/blog/BlogStandard";
import BlogDetailsOne from "./pages/detailspages/BlogDetailsOne";
import CategoryArchive from "./pages/archive/CategoryArchive";
import TagArchive from "./pages/archive/TagArchive";
import AuthorArchive from "./pages/archive/AuthorArchive";
import ContactMe from "./pages/innerpages/ContactMe";
import Faq from "./pages/innerpages/Faq";
import HomeOne from "./pages/homepages/HomeOne";
import Testimonial from "./pages/innerpages/Testimonial";
import Error from "./pages/innerpages/Error";
//import { useOnLoadImages } from './utils/useOnLoadImages';

// Import Css Here
import "./assets/scss/style.scss";
import Loader from "./common/Loader";
import ServiceDetails from "./pages/detailspages/ServiceDetails";
import ClientsPage from "./pages/innerpages/ClientsPage";
import ThemeMode from "./common/ThemeMode";
import PrivacyPolicy from "./pages/innerpages/PrivacyPolicy";
import Pricing from "./pages/innerpages/Pricing";

function App() {
  const [error, setError] = useState(null);
  const [LoadPercent, setLoadPercent] = useState(0);
  const [IsLoading, setIsLoading] = useState(true);
  //const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [features, setFeatures] = useState([]);

  const [services, setServices] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [processes, setProcesses] = useState([]);


  var preloaded = 0;
  var imageArray = 0;
  //   const wrapperRef = useRef();
  // const imagesLoaded = useOnLoadImages(wrapperRef);
  const progress = () => {
    preloaded++;
    calculatepercent();
    console.log(preloaded);

    if (preloaded == imageArray) {
      //ALL Images have been loaded, perform the desired action
      document.querySelector(".logo_preloader_logo").classList.add("complete");
      document.querySelector("body").classList.remove("logo_preloader");
      setTimeout(function () {
        setIsLoading(false);
        document.querySelector("body").classList.add("loaded");
      }, 0);
    }
  };

  const calculatepercent = () => {
    setTimeout(function () {
      setLoadPercent(parseInt((100 * preloaded) / imageArray));
    }, 0);
  };

  useEffect(() => {

    
    fetch("https://valoirinc.com/data.json")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);

          for (var i = 0; i < result[0]["brands"].length; i++) {
            var tempImage = new Image();
            tempImage.addEventListener("load", progress, true);
            console.log(result[0]["brands"][i]["image"]);
            tempImage.src = result[0]["brands"][i]["image"];
          }

          imageArray = imageArray + i;

          for (var i = 0; i < result[1]["testimonials"].length; i++) {
            var tempImage = new Image();
            tempImage.addEventListener("load", progress, true);
            console.log(result[1]["testimonials"][i]["image"]);
            tempImage.src = result[1]["testimonials"][i]["image"];
          }

          imageArray = imageArray + i;

          for (var i = 0; i < result[2]["blogs"].length; i++) {
            var tempImage = new Image();
            tempImage.addEventListener("load", progress, true);
            console.log(result[2]["blogs"][i]["image"]);
            tempImage.src = result[2]["blogs"][i]["image"];
          }

          imageArray = imageArray + i;

          //console.log(result[0]['brands']);
          setBrands(result[0]["brands"]);
          setTestimonials(result[1]["testimonials"]);
          setBlogs(result[2]["blogs"]);
          setFeatures(result[3]["features"]);
          setServices(result[4]["services"]);
          setFaqs(result[5]["faqs"]);
          setProcesses(result[6]["processes"]);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          document.querySelector("body").classList.add("loaded");
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <>
        {!IsLoading && (
          <Router>
            <ScrollToTop>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <HomeOne
                      brands={brands}
                      blogs={blogs}
                      testimonials={testimonials}
                      features={features}
                      services={services}
                      faqs={faqs}
                      processes={processes}
                    />
                  }
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/about-us"}`}
                  element={<AboutUsOne testimonials={testimonials} />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/contact-us"}`}
                  element={<ContactMe />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/our-clients"}`}
                  element={<ClientsPage brands={brands} />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/faqs"}`}
                  element={<Faq faqs={faqs} />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/testimonials"}`}
                  element={<Testimonial testimonials={testimonials} />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/blogs"}`}
                  element={<BlogStandard blogs={blogs} />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/pricing"}`}
                  element={<Pricing />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
                  element={<PrivacyPolicy />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
                  element={<BlogDetailsOne />}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
                  element={<CategoryArchive />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/service/:slug"}`}
                  element={<ServiceDetails services={services} />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                  element={<TagArchive />}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL + "/author/:slug"}`}
                  element={<AuthorArchive />}
                />
                <Route path="*" element={<Error />} />
              </Routes>
            </ScrollToTop>
          </Router>
        )}
        {/* <ThemeMode /> */}
        <Loader percentage={LoadPercent} />
      </>
    );
  }
}

export default App;
