import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const AboutUsThreeMission = () => {
    return (
        <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="thumbnail mb--50">
                            <div className="sshape-image image-1">
                                <Parallax speed={1} translateY={[0, 40]} tagouter="figure">
                                    <img src="/images/mission/mission-1.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="sshape-image image-2">
                                <Parallax speed={1} translateY={[0, -20]} tagouter="figure">
                                    <img src="/images/mission/mission-2.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="sshape-image image-3">
                                <Parallax speed={1} translateY={[0, 30]} tagouter="figure">
                                    <img src="/images/mission/mission-3.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                            <div className="sshape-image image-4">
                                <Parallax speed={1} translateY={[0, -18]} tagouter="figure">
                                    <img src="/images/mission/mission-4.jpg" alt="Gallery Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper harddisplay d-xl-block d-none">
                    
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                    <div className="shape shape-2">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="our-mission">
                            <div className="section-title text-start">
                                <span className="pre-title">Mission</span>
                                <h4 className="title-">Our mission is to make the admission process more efficient for university applicants.</h4>
                                <p className="mt--30 mb--0">We provide automated marketing and admissions solutions that empower higher education institutions to attract, engage and enroll more students.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="our-vision">
                            <div className="section-title text-start">
                                <span className="pre-title">Vision</span>
                                <h4 className="title-">Our vision is to enroll smart students in the best universities around the world.</h4>
                                <p className="mt--30 mb--0">We believe that every student deserves access to quality higher education, and we are committed to making that a reality. Every student has the potential to succeed, and we work tirelessly to help them reach their full potential.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutUsThreeMission;