import React from "react";
import { Link } from "react-router-dom";
import LetsTalkButton from "./LetsTalkButton";
import ScrollTopButton from "./ScrollTopButton";

const FooterOne = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <img
                      src="/images/logo/white-logo-round.png"
                      alt="VALOIR INC."
                    />

                    <img src="/images/logo/icef-logo.png" alt="Main Logo" />
                  </div>
                  <p className="description">
                    We are the pioneers in the field of international student
                    recruitments, recruitment process automation, international
                    digital marketing and digital academic networking.
                  </p>
                  <ul className="social-share">
                    <li>
                      <a href="https://www.facebook.com/Valoir-Inc-187810138508117">
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/valoir-inc/">
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ValoirInc">
                        <i className="icon-Twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Explore</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/about-us">
                          <i className="icon-Double-arrow"></i>About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/marketing-strategy">
                          <i className="icon-Double-arrow"></i>Marketing
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/consulting/">
                          <i className="icon-Double-arrow"></i>Consulting
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/recruiting/">
                          <i className="icon-Double-arrow"></i>Recruiting
                        </Link>
                      </li>
                      <li>
                        <Link to="/service/monitoring/">
                          <i className="icon-Double-arrow"></i>Monitoring
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">Useful Links</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/contact-us">
                          <i className="icon-Double-arrow"></i>Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/pricing">
                          <i className="icon-Double-arrow"></i>Make a payment
                        </Link>
                      </li>
                      <li>
                        <Link to="/blogs">
                          <i className="icon-Double-arrow"></i>Blog & News
                        </Link>
                      </li>
                      <li>
                        <Link to="/faqs">
                          <i className="icon-Double-arrow"></i>FAQ Question
                        </Link>
                      </li>
                      <li>
                        <Link to="/testimonials">
                          <i className="icon-Double-arrow"></i>Testimonial
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">
                          <i className="icon-Double-arrow"></i>Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Contact Info</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>1250 RUE DUFORT,
                          MONTREAL, QC H3H 2B6, CANADA
                        </li>
                        <li>
                          <i className="icon-map-pin-line"></i>2322 SHATTUCK
                          AVE, BERKELEY, CALIFORNIA 94704, USA
                        </li>
                        <li>
                          <i className="icon-map-pin-line"></i>2D, Lakshmi
                          Rukmini Estates, Sikh Village, Secunderabad,
                          Telangana, India
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +1-647-618-5678">+1-647-618-5678</a>
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: +1-514-220-9752">+1-514-220-9752</a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a target="_blank" href="mailto:info@valoirinc.com">
                            info@valoirinc.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2023 <a href="#">VALOIR INC</a> Designed By{" "}
                    <a href="#">MaxVibes</a>. All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <LetsTalkButton /> 
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
