import React , { useState } from 'react';

const Result = () => {
    return (
        <p className="success-message">Thanks for your query. We will contact with you soon.</p>
    )
}

function ContactMeForm( { formStyle } ) {
    const [submit, setSubmit] = useState( 'Submit Now' );
    const [result, setResult] = useState( false );
    const [name, setName] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [subject, setSubject] = useState( '' );
    const [message, setMessage] = useState( '' );

    const sendEmail = ( e ) => {
        e.preventDefault();
        
        setSubmit('Submitting...');

        
        let sendData = {
            name : name,
            email : email,
            subject : subject,
            message : message
        };
        /** use for test https://cors-anywhere.herokuapp.com/http://valoirinc.com/data-center/wp-admin/admin-ajax.php?action=book_appointment */ 
        fetch('http://valoirinc.com/data-center/wp-admin/admin-ajax.php?action=contact_us_mail', {
      method: "POST",
      body: JSON.stringify(sendData),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(
    (response) => (response.json())
      ).then((response)=> {
    if (response.status==='1') {
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setResult( true );
        setSubmit('Submitted');
    } else{
      alert("Message failed to send." + response.errmsg);
    }
  });

    };

    setTimeout( () => {
        setResult( false );
    }, 5000);

    return (
        <form className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
            <div className="col-lg-6">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="fullname" placeholder="Name*" required />
                </div>
            </div>

            <div className="col-lg-6">
                <div className="form-group">
                    <input type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required/>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="form-group">
                    <input type="text" className="form-control form-control-lg" name="subject" placeholder="Subject" />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                    <textarea className="form-control" name="message" placeholder="Your comment"></textarea>
                </div>
            </div>
            <div className="col-lg-12">
                <button className="rn-btn edu-btn" type="submit">
                    <span>{submit}</span><i className="icon-arrow-right-line-right"></i>
                </button>
            </div>
            { result ? <div className="form-group"><Result /></div>  : null }
        </form>
    )
}
export default ContactMeForm;