import React from 'react'

const LetsTalkButton = () => {

    React.useEffect(() => {
        

        let offset = 1000;
    window.addEventListener( 'scroll', function () {
      if (window.pageYOffset > offset) {
        document
          .querySelector( '.letstalkbutton' ).classList.add( 'make-active' );
      } else {
        document
          .querySelector( '.letstalkbutton' )
          .classList.remove( 'make-active' );
      }
    });

        
         


    }, []);

  return (
    <div className='letstalkbutton'>
      <a href="tel: +1-647-618-5678" className="edu-btn shadow-btn call-btn">Let’s Talk
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M5.96875 7C5.96875 6.46875 5.5 6 4.96875 6H4.5C3.375 6 2.5 6.90625 2.5 8.03125V9.5C2.5 10.625 3.375 11.5 4.5 11.5H5C5.53125 11.5 6 11.0625 6 10.5L5.96875 7ZM8 0C3.5 0 0.125 3.75 0 8V9.25C0 9.6875 0.3125 10 0.71875 10C1.09375 10 1.5 9.6875 1.5 9.25V8C1.5 4.4375 4.40625 1.53125 8 1.53125C11.5625 1.53125 14.5 4.4375 14.5 8V12.5C14.5 13.2188 13.9375 13.75 13.25 13.75H9.78125C9.53125 13.3125 9.03125 13 8.5 13H7.53125C6.8125 13 6.15625 13.5 6 14.2188C5.84375 15.1875 6.5625 16 7.46875 16H8.5C9.03125 16 9.53125 15.7188 9.78125 15.25H13.25C14.75 15.25 16 14.0312 16 12.5V8C15.8438 3.75 12.4688 0 8 0ZM11.5 11.5C12.5938 11.5 13.5 10.625 13.5 9.5V8.03125C13.5 6.90625 12.5938 6 11.5 6H11C10.4375 6 10 6.46875 10 7.03125V10.5C10 11.0625 10.4375 11.5 11 11.5H11.5Z" fill="#3B38EB"></path>
                            </svg>
                        </a>
    </div>
  )
}

export default LetsTalkButton
