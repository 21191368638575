import React from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import { Parallax } from 'react-scroll-parallax';

const items = [
  {
    title: "24/7 Call Services Available",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Hand---Book",
  },
  {
    title: "Great Skilled Consultant",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Campus",
  },
  {
    title: "Expert Team Members",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Hand---Book",
  },
  {
    title: "Business Growth Model",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Campus",
  },
  {
    title: "Strategy Growth Check",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Hand---Book",
  },
  {
    title: "Digital Solution",
    info: "It is a long established fact that a reader will be distracted by this on readable content of when looking at its layout.",
    icon: "icon-Campus",
  },
];

const HomeOneAbout = ({items}) => {
  return (
    <Element
      name="about-us"
      className="edu-about-area about-style-1 about-style-6 edu-section-gap bg-primary-alt-color"
    >
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="about-image-gallery">
              <img
                className="image-1"
                src="images/about/about-09/about1.jpg"
                alt="About Main Thumb"
              />
              <div className="image-2">
                
                <Parallax translateY={[40, -10]} tagouter="figure">
                                    <img src="images/about/about-09/about2.jpg" alt="About Images" />
                  </Parallax>
              </div>
              <div className="badge-inner">
                <img
                  className="image-3"
                  src="/images/about/about-09/badge.png"
                  alt="About Circle Thumb"
                />
              </div>
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <SectionTitle
                slogan="Who We Are"
                title="Students Recruitment Agency for Universities and Colleges"
              />
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  We are a hybrid agency catering to the strategic higher
                  education marketing & branding needs of Colleges, Universities
                  and Schools.
                </p>
              </ScrollAnimation>

              {items && items.length > 0 && (
                <div className="feature-list-wrapper">
                  {items.filter(item => item.core_feature < 1).map((data, i) => (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      className="feature-list"
                      animateOnce={true}
                      key={i}
                    >
                      <i className="icon-checkbox-circle-fill"></i>
                      {data.title}
                    </ScrollAnimation>
                  ))}
                </div>
              )}
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                className="read-more-btn"
                animateOnce={true}
              >
                <Link className="edu-btn" to="/about-us">
                  Know About Us<i className="icon-arrow-right-line-right"></i>
                </Link>
                <a className="info-btn" href="tel: +1-647-618-5678">
                  <i className="icon-call"></i>
                  <span className="d-inline-flex btn-group-vertical">
                    <span>Call us anytime</span>
                    <span> +1-647-618-5678</span>
                  </span>
                </a>
              </ScrollAnimation>
              
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default HomeOneAbout;
