import React, {useEffect} from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutSeven from '../../components/about/AboutSeven';
import AboutUsOneService from '../../components/about-us-one/AboutUsOneService';
import AboutSix from '../../components/about/AboutSix';
import AboutOne from '../../components/about/AboutThree';
import AboutUsOneTeam from '../../components/about-us-one/AboutUsOneTeam';
import HomeTwoTestimonial from '../../components/home-two/HomeTwoTestimonial';
import FooterTwo from '../../common/footer/FooterTwo';
import FooterOne from '../../common/footer/FooterOne';
import AboutUsThreeMission from '../../components/about-us-three/AboutUsThreeMission';
import CounterUpOne from '../../components/counterup/CounterUpOne';
import NewsLettterOne from '../../components/newsletter/NewsLettterOne';

const AboutUsOne = ({testimonials}) => {

    useEffect(() => {
        document.body.classList.add('innerpage');
      }, []);


    return (
        <>
            <SEO title="About Us" />

            <HeaderTwo styles="header-transparent- header-style-2" />

            <BreadcrumbOne 
                title="About Us"
                rootUrl="/"
                parentUrl="Home"
                currentUrl="About Us"
            />

            <AboutSeven />
            <CounterUpOne />
            <AboutUsThreeMission />
            
            

            <NewsLettterOne />

            <HomeTwoTestimonial testimonials={testimonials}/>

            <FooterOne />
        </>
    )
}

export default AboutUsOne;