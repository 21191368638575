import React, { useState, useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../sectionTitle/SectionTitle";

const accordionItems = [
  {
    id: 1,
    title: "How can we help in Marketing and Mobility Ecosystem Promotions?",
    body: "With Valoir, you can enhance your marketing efforts with more customized and relevant information. By connecting with Valoir, you receive more visibility on our International Student recruitment process . All Institutions will have an Institution Profile on our dedicated website and partner sites, which will be viewed by Students, Agents and other visitors globally.",
  },
  {
    id: 2,
    title: "Where do you get students from?",
    body: "Our students come from the best universities & colleges from different parts of world. We do have the best agent network all over the world, which puts Valoir Inc ahead of the competition. We also recruit students through our own agent network Volant Overseas Private Limited",
  },
  {
    id: 3,
    title: "What kind of services do you offer?",
    body: "We offer educational counseling, university selection, study visa assistance, immigration assistance, overseas work permit, immigration and other services related to educational consulting.",
  },
  {
    id: 4,
    title: "How many agents are listed with Valoir Inc?",
    body: "Our agent community consists of more than 900 educational consultants from over 70 countries offering services to Students, Agents, & Educational Institutions.",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const AboutTwo = ({faqs}) => {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <div className="edu-accordion-area eduvibe-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5">
          <div className="col-lg-6 gallery-wrapper">
            <div className="row">

            <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="thumbnail">
                  <img
                    className="radius-small w-100"
                    src="/images/about/about-02/about-3.jpg"
                    alt="About Thumb 1"
                  />
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                <div className="thumbnail">
                  <img
                    className="radius-small w-100"
                    src="/images/about/about-02/about-1.jpg"
                    alt="About Thumb 2"
                  />
                </div>
                <div className="thumbnail mt--15">
                  <img
                    className="radius-small w-100"
                    src="/images/about/about-02/pexels-pavel-danilyuk-7942502.jpg"
                    alt="About Thumb 3"
                  />
                </div>
              </div>

              

            </div>
          </div>

          <div className="col-lg-6">
            <SectionTitle
              classes="text-start mb--40"
              slogan="Common Questions"
              title="Get Every General Answers From Here"
            />
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              className="accordion-style-1"
              animateOnce={true}
            >
              <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                {faqs.map((accordion, i) => (
                  <Accordion.Item
                    eventKey={i.toString()}
                    key={i}
                    onClick={() => toggleActive(i.toString())}
                    bsPrefix={`edu-accordion-item ${
                      activeId === i.toString() ? "active-item" : ""
                    }`}
                  >
                    <div className="edu-accordion-header">
                      <CustomToggle eventKey={i.toString()}>
                        {accordion.title}
                      </CustomToggle>
                    </div>
                    <Accordion.Body bsPrefix="edu-accordion-body">
                      <div dangerouslySetInnerHTML={{__html: accordion.body}} />                      
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </ScrollAnimation>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default AboutTwo;
