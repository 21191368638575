import React, {useEffect} from 'react';

import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import HomeOneAbout from '../../components/home-one/HomeOneAbout';
import HomeTwoService from '../../components/home-two/HomeTwoService';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';

import VideoSectionTwo from '../../components/video-section/VideoSectionTwo';
import NewsLettterOne from '../../components/newsletter/NewsLettterOne';
import HomeOneBlog from '../../components/home-one/HomeOneBlog';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import FooterOne from '../../common/footer/FooterOne';
import CallToActionTwo from '../../components/cta/CallToActionTwo';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import HomeFourBlog from '../../components/home-four/HomeFourBlog';
import HomeFiveBlog from '../../components/home-five/HomeFiveBlog';
import AboutTwo from '../../components/about/AboutTwo';
import AboutFive from '../../components/about/AboutFive';
import { useHashFragment } from '../../utils/useHashFragment';

const HomeOne = ({brands, testimonials, blogs, features, services, faqs, processes}) => {

    

    
    
    useEffect(() => {
        document.body.classList.remove('innerpage');
      }, []);

    return (
        <>
            <SEO title="Home" />

            <HeaderTwo styles="header-transparent header-style-2" />

            <BannerOne brands={brands}  />

  

            <HomeOneAbout items={features} />         

            <HomeTwoService services={services} />

            <AboutFive features={features} />

            <NewsLettterOne />

            <AboutTwo faqs={faqs} />
            
            <HomeFiveProgress processes={processes} />

            <CallToActionTwo />

<TestimonialSectionOne  testimonials={testimonials} />

<HomeFiveBlog blogs={blogs} />    

            <FooterOne />   
        </>
    )
}

export default HomeOne;