import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

const ServiceTwo = ( { wrapperClass, styleClass, services } ) => {
    console.log(services);
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { services.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-sm-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-4">
                        <div className="inner">
                            <div className="icon">
                                <i className={ data.icon }></i>
                                <span className="subtitle"><i className={ data.icon2 }></i></span>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default ServiceTwo
