import PropTypes from 'prop-types';
import React from 'react'
import { Helmet } from 'react-helmet-async';
// import { Helmet } from 'react-helmet';


const SEO = ( { title } ) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ title } || Valoir Inc &#8211; Recruiting aspiring international students for higher education</title>
            
            <meta name="description" content="Valoir Inc has been on the frontline of digital student recruitment test" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;