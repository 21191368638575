import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li>
                <Link to="/">Home</Link>
            </li>

            <li>
                <Link to="/about-us/">About Us</Link>
                
            </li>
            
            <li className="has-droupdown">
                <Link to="#">Services</Link>
                <ul className="submenu">
                    <li><Link to="/service/marketing-strategy/">Marketing</Link></li>
                    <li><Link to="/service/recruiting/">Recruiting</Link></li>
                    <li><Link to="/service/statistics/">Statistics</Link></li>
                    <li><Link to="/service/background-check/">Background Check</Link></li>
                    <li><Link to="/service/monitoring/">Monitoring</Link></li>
                    <li><Link to="/service/time-bound/">Time Bound</Link></li>
                    <li><Link to="/service/consulting/">Consulting</Link></li>
                </ul>
            </li>

            <li>
                <Link to="/testimonials/">Testimonials</Link>
                
            </li>

            <li>
                <Link to="/our-clients/">Our Clients</Link>
                
            </li>
            
            
            <li>
                <Link to="/faqs/">FAQs</Link>
                
            </li>
            

            <li>
                <Link to="/contact-us">Contact Us</Link>
            </li>

        </ul>
    )
}
export default Nav;
