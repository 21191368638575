import React from 'react';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import ServiceTwo from '../service/ServiceTwo';

const HomeTwoService = ({services}) => {
    return (
        <div className="sercice-area eduvibe-service-four edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "Our Services"
                            title = "Service We Provide"
                        />
                    </div>
                </div>

                <ServiceTwo services={services}/>

               
            </div>
        </div>
    )
}

export default HomeTwoService;