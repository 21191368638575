import React from 'react'
import Slider from "react-slick";
import { BrandsSliderParams } from "../../utils/script";
const Brands = ({brands}) => {
  return (
    <div className="rts-brand-area rts-section-gapBottom">
        <div className="container-">
          <div className="row">
            <div className="col-12">
              <div className="brand-one-wrapper">
                <div className="swiper brand-1">
                  <div className="swiper-wrapper">
                    <Slider
                      className="slick-gutter-15 brand-slider"
                      {...BrandsSliderParams}
                    >

{brands.map((brand) => (
            <div className="swiper-slide" key={brand.id}>
                        <a href="#">
                          <img
                            src={brand.image}
                            alt={brand.name}
                          />
                        </a>
                      </div>
          ))}
                      
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Brands
