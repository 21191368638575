import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import HowItWorksOne from '../how-it-works/HowItWorksOne';

const HomeFiveProgress = ({processes}) => {
    return (
        <div className="eduvibe-home-five-progress  edu-service-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = "How We Work"
                            title = "Our Work Process"
                        />
                    </div>
                </div>

                <HowItWorksOne processes={processes} />

                
            </div>
        </div>
    )
}

export default HomeFiveProgress;