import React from "react";

import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import { useHashFragment } from "../../utils/useHashFragment";
import Brands from "../brands/Brands";


const BannerOne = ({brands}) => {

  

  const redirectToAppointment = (e) => {
    e.preventDefault();
    window.location.href = "#make-appointment";
  };
  

  return (
    <div className="slider-area banner-style-1 bg-white bg-image height-940 d-flex- rts-banner-three align-items-center">
      
      <span className="background-text top--15 left--2">
                                                    Education
                                                </span>

                                                <span className="background-text top--40 left--5">
                                                    Marketing
                                                </span>

                                                <span className="background-text bottom--20 right--0">
                                                   Solutions
                                                </span>
      
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 row--40 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-6">
            <div className="banner-left-content">
              <div className="inner">
                <div className="content">
                  <span className="pre-title">
                    A Team with best Organizational Skills
                  </span>
                  <h1 className="title">
                    Recruit more high-value students with our specialized
                    solutions.
                  </h1>
                  <p className="description">
                    Valoir Inc has been on the frontline of digital student
                    recruitment since 2007.
                  </p>
                  <div className="read-more-btn">
                    <Link className="edu-btn btn-primary" to="/#make-appointment" onClick={redirectToAppointment}>
                      Get Started Today{" "}
                      <i className="icon-arrow-right-line-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-2 order-xl-2 col-lg-12 col-xl-6 banner-right-content">
            <div className="row g-5">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="edu-card card-type-6 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <Link to="/service/marketing-strategy/">
                        <img
                          className="w-100"
                          src="images/banner/banner-01/marketing.jpg"
                          alt="Marketing"
                        />
                      </Link>
                      <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-01 bg-secondary-color">
                          Digital Marketing
                        </span>
                      </div>
                    </div>
                    <div className="content">
                      <h6 className="title">
                        <Link to="/service/marketing-strategy/">
                          We promote your institution to students...
                        </Link>
                      </h6>
                      <div className="card-bottom">
                        <p>
                          Many strategies exist to help your Institution make a
                          great...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edu-card second card-type-6 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <Link to="/service/recruiting">
                        <img
                          className="w-100"
                          src="images/banner/banner-01/recruiting.jpg"
                          alt="Recruiting"
                        />
                      </Link>
                      <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-01 bg-primary-color">
                          Online Recruiting
                        </span>
                      </div>
                    </div>
                    <div className="content">
                      <h6 className="title">
                        <Link to="/service/recruiting">
                          Our recruitment process typically...
                        </Link>
                      </h6>
                      <div className="card-bottom">
                        <p>
                          Finding the right people is always a challenge, but it
                          can be...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="edu-card third card-type-6 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <Link to="/service/monitoring">
                        <img
                          className="w-100"
                          src="images/banner/banner-01/monitoring.jpg"
                          alt="Monitoring"
                        />
                      </Link>
                      <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-01 bg-primary-color">
                          Monitoring
                        </span>
                      </div>
                    </div>
                    <div className="content">
                      <h6 className="title">
                        <Link to="/service/monitoring">
                          We protect our clients from unreliable contacts...
                        </Link>
                      </h6>
                      <div className="card-bottom">
                        <p>
                          Valoir Inc provides support for your institution to
                          distinguish between the potential students and frauds.
                          Our strong agent network allows you to assess
                          potential students...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        
      </div>

      <Brands brands={brands} />

      <div className="scroll-down-btn">
        <ScrollTo
          className="round-btn"
          to="about-us"
          spy={true}
          smooth={true}
          duration={200}
        >
          <i className="icon-arrow-down-s-line"></i>
        </ScrollTo>
      </div>
      
    </div>
  );
};
export default BannerOne;
